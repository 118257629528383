body {
  font-family: "Montserrat", sans-serif !important;
  max-width: 420px;
  /* overflow: hidden; */
  color: black;
}

/* Hide scrollbar for all elements */
body::-webkit-scrollbar {
  width: 0;
  height: 0;
  background-color: transparent;
}

/* Optional: Hide scrollbar on specific element */
.container::-webkit-scrollbar {
  display: none;
}

a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
li,
ul,
button,
input {
  font-family: "Montserrat", sans-serif !important;
}
input {
  border-radius: 5px;
  border: 1px solid;
  padding: 6px 10px;
  margin: 4px 0;
  transform: none !important;
}

a {
  font-size: 13px;
  text-decoration: none;
  color: black;
}

span {
  color: #d9363e;
}

.view-btn {
  background: #db454d;
  color: white;
  border: none;
  border-radius: 3px;
  padding: 3px 10px;
}

.address-view {
  margin: 0 !important;
  border-radius: 5px;
}

.address-view p {
  margin: 5px 0;
  font-size: 13px;
}

.delivery-note-wrapper {
  border: 2px solid #d9363e;
  padding: 5px 10px;
  background: beige;
}

.delivery-note-wrapper h4 {
  margin: 0;
}

.items-listing {
  border: 2px solid #d9363e;
  margin: 10px 0;
  padding: 5px 10px;
  border-radius: 5px;
}

.items-listing h5 {
  width: 100%;
}

.order-status-btn {
  padding: 10px;
  font-weight: 700;
  background: #d9363e;
  border: none;
  color: white;
  border-radius: 5px;
  min-width: 350px;
  width: 100%;
}

.order-history {
  padding: 20px;
}

.order-history h4 {
  color: #d9363e;
  text-align: center;
  margin: 5px auto;
}

.order-history hr {
  background: #d9363e;
  height: 2px;
}

.order-history .h5Top {
  width: 33.33%;
}

.order-history .top-items-wrapper {
  display: flex;
  justify-content: space-between;
}
.loginBtn {
  width: 100%;
  padding: 8px;
  font-weight: 600;
  background: #d9363e;
  border: none;
  border-radius: 5px;
  color: white;
  margin-top: 5px;
}
.loginh1 {
  color: #d9363e;
  text-align: center;
  font-size: 20px;
  margin: 5px 0;
}
.loginh4 {
  color: black;
  text-align: center;
  margin: 10px;
  font-size: 13px;
}
.ant-menu-title-content button {
  width: 100%;
  border: none;
  border-radius: 5px;
  font-family: inherit;
  font-weight: 700;
  color: black;
}
.toast-message {
  background: darkblue;
  color: #fff;
  font-size: 20px;
  width: 34vw;
  padding: 30px 20px;
}

.login-page .Toastify__toast-container {
  width: 300px;
}

.login-page .Toastify__toast {
  border-radius: 10px;
  position: absolute;
  right: 0;
  top: 230px;
}

.my-input-field {
  transform: none !important;
}

.deliveryPin {
  width: 94%;
  margin: 10px auto;
  display: flex;
  border: 2px solid;
  background: #dcded8;
  font-weight: 700;
}

.ant-spin {
  justify-content: center;
  display: flex;
  height: fit-content !important;
  align-items: center;
  width: 100vw !important;
  margin: auto !important;
  position: absolute !important;
  top: 155px !important;
}

.ant-spin span {
  font-size: 30px !important;
  color: #db454d !important;
}
.popup {
  position: absolute;
  bottom: 75px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.popup p {
  background: black;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  width: fit-content;
  font-size: 12px;
}
