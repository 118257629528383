.orderPageWrapper h5 {
  margin: 5px 0;
  font-weight: 500;
  /* width: 170px; */
}
.orderPageWrapper h6 {
  margin: 0;
  font-weight: 500;
  color: darkgray;
}
.orderPageWrapper {
  padding: 30px 10px 10px;
}
.itemsWrapper {
  border: 2px solid #d9363e;
  border-radius: 5px;
  margin: 10px 0;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.itemsWrapper div div {
  margin: 6px 0;
}
.customerDetailsWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  text-align: end;
  width: 100%;
}
.customerDetailsWrapper a {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.restaurantDetailsWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.callBtn {
  padding: 5px 15px;
  border: none;
  border-radius: 5px;
  font-weight: 500;
  background: #d9363e;
  color: white;
  text-align: center;
  align-self: end;
  width: 125px;
}
.customerName h5 {
  width: 100%;
}
.restaurantName {
  width: 40%;
}
.callCustomerBtn {
  /* position: absolute;
  top: 0; */
  height: 15px;
  width: 125px !important;
}
.callBtnWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
