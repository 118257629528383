h5 {
  margin: 0;
  font-weight: 500;
}
h6 {
  margin: 0;
  font-weight: 500;
  color: darkgray;
}

.homeWrapper {
  display: flex;
}

.headerWrapper {
  -webkit-box-shadow: 0 4px 6px -6px #222;
  -moz-box-shadow: 0 4px 6px -6px #222;
  box-shadow: 0 4px 6px -6px #222;
  padding: 10px 15px;
}

.headerWrapper div {
  position: absolute;
  top: 0;
  left: 0;
}

.headerWrapper button {
  /* background-color: #d9363e !important; */
  background-color: #fff !important;
  left: 15px;
  top: 20px;
  z-index: 999;
  height: 30px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.headerWrapper button span {
  color: black !important;
}

.headerWrapper ul {
  top: -8px;
  position: absolute;
  width: 256px;
  height: 100vh;
  padding: 0;
  background: #b5230a;
  /* background: rgb(181, 35, 10); */
  /* background: linear-gradient(
    114deg,
    rgba(181, 35, 10, 1) 0%,
    rgba(0, 0, 0, 0.7931766456582633) 87%
  ); */
  color: white;
}

.headerWrapper ul li:nth-child(1) {
  margin-top: 100px;
}

.headerWrapper ul li {
  height: 75px !important;
}

.headerWrapper ul span {
  color: white;
}

.headerWrapper ul span a {
  font-size: 18px;
  font-weight: 600;
}

.headerWrapper ul p {
  margin: 0;
  font-size: 10px;
  flex-wrap: wrap;
  width: 100%;
  display: flex;
  height: 30px;
  line-height: 14px;
  color: burlywood;
}

.homeWrapper {
  margin: 10px auto;
}

.homeWrapper button {
  padding: 5px 10px;
  background: none;
  border: none;
  font-size: 15px;
  font-weight: 600;
  width: 140px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
  margin: 6px;
}

.btnActive {
  color: #d9363e !important;
}
.divActive {
  border-bottom: 3px solid #d9363e !important;
}

.homeWrapper div {
  width: 50%;
  justify-content: center;
  display: flex;
}

.homeWrapper span {
  width: 20px;
  height: 20px;
  background-color: #d9363e;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 5px;
  color: white;
}

.dataWrapper {
  padding: 10px 15px;
  border: 2px solid #edcf59;
  border-radius: 5px;
  margin: 10px 0;
}

.detailsTopWrapper {
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  padding-bottom: 25px;
}

.detailsTopWrapper span {
  font-size: 12px;
}

.bottomDetailsWrapper {
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.bottomDetailsWrapper a {
  padding: 6px 15px;
  border: none;
  border-radius: 5px;
  font-weight: 600;
  background: #d9363e;
  color: white;
  text-decoration: none;
}

.restaurantNameSpan {
  font-weight: 700;
}

.loadingIndicator {
  /* position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: center; */
  transition: transform 0.3s ease;
  transform: translateY(4%);
}
.deliveryNoteWrapper {
  border-radius: 6px;
  border: 2px solid saddlebrown;
  background: bisque;
  padding: 6px 8px;
  margin-bottom: 10px;
}
.deliveryNoteWrapper p {
  margin: 0;
}

/* .loadingIndicator.show {
  transform: translateY(3%);
} */
