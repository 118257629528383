.orderItemContainer {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  margin: 10px 0;
  border: 2px solid #d9363e;
  border-radius: 5px;
}
.orderItemContainer div {
  width: 50%;
}
.orderItemContainer h5,
h6 {
  margin: 5px 0;
}
.loadMoreBtn {
  width: 100%;
  padding: 10px 5px;
  color: white;
  background: #c12222;
  border-radius: 6px;
  border: none;
}
